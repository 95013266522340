import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import ArticleThumbnail  from '../components/ArticleThumbnail'
import SectionTitle from '../components/SectionTitle'

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title

    return (
      <Layout>
        <section className="section">
          <Helmet title={`${tag} | ${title}`} />
          <div className="container content">
            <div className="columns">
              <div
                className="column is-10 is-offset-1"
                style={{ marginBottom: '6rem' }}
              >
                <h2>
                  <SectionTitle>{tag}</SectionTitle>
                </h2>
                <div className="columns is-multiline">
                  {posts.map(({node: post}) => (
                    <div className="column is-4" key={post.id}>
                      <ArticleThumbnail
                        slug={post.fields.slug}
                        columnist={post.frontmatter.columnist}
                        featuredimage={post.frontmatter.featuredimage}
                        title={post.frontmatter.title}
                        excerpt={post.excerpt}
                        date={post.frontmatter.date}
                      />
                    </div>
                  ))}
                </div>
                <p>
                  <Link className="primary-link" to="/tag/">所有標籤</Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 100
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          ...articleThumbFields
        }
      }
    }
  }
`
